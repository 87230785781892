<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(show_factory)="data">

        <div class="text-center">
          <span
            v-if="viewContent"
            title="View"
          >
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'show-facility',
                  params: { id: data.item.id }
                })
              "
            />
          </span></div></template>

      <template #cell(activityar)="data">
        <div class="text-center">
          <span
            v-if="data.item.activity"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.activity.ar || ' ' }}
          </span></div></template>

      <template #cell(activityen)="data">
        <div class="text-center">
          <span
            v-if="data.item.activity"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.activity.en || ' ' }}
          </span></div></template>

      <template #cell(nameen)="data">
        <div class="text-center">
          <span
            v-if="data.item.name"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.name.en || ' ' }}
          </span></div></template>

      <template #cell(namear)="data">
        <div class="text-center">
          <span
            v-if="data.item.name"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.name.ar || ' ' }}
          </span></div></template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'factory',
      // addType: 'Add City',
      // addComponentName: 'add-city',
      // editComponent: 'edit-city',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'namear', label: 'Name Ar' },
        { key: 'nameen', label: 'Name En' },
        { key: 'activityar', label: 'Activity Ar' },
        { key: 'activityen', label: 'Activity En' },
        { key: 'CR_num', label: 'CR Number' },
        { key: 'CR_end_date', label: 'CR End Date' },
        { key: 'phone', label: 'Phone' },
        // { key: 'address', label: 'Address' },
        { key: 'zip_code', label: 'Zip Code' },
        { key: 'postal_code', label: 'Postal Code' },
        { key: 'audit_license_expiration', label: 'Audit License Expiration' },
        { key: 'survialnce', label: 'Survialnce' },
        { key: 'show_factory', label: 'Show Info' },
        // { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'factory?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style>
